import React from 'react';

const Sphere = (isLoading2) => {
    const TagCloud = require('TagCloud');

    const myTags = [
        'JavaScript', 'CSS3', 'HTML5',
        'Next.js', 'Tailwind', 'React',
        'MongoDB', 'Sequelize', 'github',
        'Mongoose', 'Node.js', 'WordPress',
        'Scss', 'MySQL', 'W3C',
    ];

    let tagCloud = TagCloud('.content', myTags, {

        // radius in px
        radius: 250,

        // animation speed
        // slow, normal, fast
        maxSpeed: 'fast',
        initSpeed: 'fast',

        // 0 = top
        // 90 = left
        // 135 = right-bottom
        direction: 135,

        // interact with cursor move on mouse out
        keep: true

    });

    //To change the color of text randomly
    // var colors = ['#34A853', '#FBBC05', '#4285F4', '#7FBC00', 'FFBA01', '01A6F0'];
    // var random_color = colors[Math.floor(Math.random() * colors.length)];
    // document.querySelector('.content').style.color = random_color;
    return (
        <div >
            <span className='content' ></span>

            <script src="https://cdn.jsdelivr.net/npm/TagCloud@2.2.0/dist/TagCloud.min.js" />
        </div>
    );
};

export default Sphere;